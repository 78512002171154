<template>
  <el-dialog
    title="微信换绑"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户手机号" prop="phone">
        <el-input
          type="text"
          placeholder="请输入用户手机号"
          v-model="modalData.phone"
        ></el-input>
      </el-form-item>

      <el-form-item label="目标手机号" prop="targetPhone">
        <el-input
          type="text"
          placeholder="请输入目标手机号"
          v-model="modalData.targetPhone"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        targetPhone: [
          {
            required: true,
            message: "目标用户手机号",
            trigger: "change",
          },
        ],

        phone: [
          {
            required: true,
            message: "用户手机号",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
  },

  methods: {

    submit() {
      let obj = {
        phone: this.modalData.phone,
        targetPhone: this.modalData.targetPhone,
      };
      this.validateForm().then((res) => {
        this.$emit("submit", obj);
      });
    },
  },
};
</script>